import * as React from 'react';

import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import pixiv from "../../Assets/icon/logo_icon.png";

import Styles from "./Card.module.css";

interface ICardProps {
    twitter?: string;
    pixivId?: string;
    name: string;
    desc?: string[];
    image?: string;
}

const Card: React.FunctionComponent<ICardProps> = (props) => {
    return (
        <div className={Styles.card} key={"card-" + props.name}>
            {!props.image && props.twitter &&
                <a href={"https://twitter.com/" + props.twitter}>
                    <div className={Styles.bubble}>
                        <img className={Styles.image} src={"https://unavatar.io/twitter/" + props.twitter} alt="twitter" />
                    </div>
                </a>
            }
            {props.image &&
                <div className={Styles.bubble}>
                    <img className={Styles.image} src={props.image} alt="custom" />
                </div>
            }
            <div className={Styles.title}>{props.name}</div>
            {props.desc &&
                <div className={Styles.desc}>{props.desc.map((x, i) => <div key={"desc-" + props.name + "-" + i}>{x}</div>)}</div>
            }
            <div className={Styles.socials}>
                {props.twitter &&
                    <a href={"https://twitter.com/" + props.twitter}>
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                }
                {props.pixivId &&
                    <a href={"https://www.pixiv.net/en/users/" + props.pixivId}>
                        <img src={pixiv} width={40} alt="pixiv logo" />
                    </a>
                }
            </div>
        </div>
    );
};

export default Card;

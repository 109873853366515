import React from 'react'

import animation1 from "../../Assets/animation/animation1.webm";
import animation2 from "../../Assets/animation/animation2.gif";
import animation3 from "../../Assets/animation/animation3.gif";
import animation4 from "../../Assets/animation/animation4.webm";

import liora from "../../Assets/animation/liora.webm";
import amoria from "../../Assets/animation/amoria.webm";
import serafi from "../../Assets/animation/serafi.webm";

import Styles from "./Animations.module.css";

interface Props {

}

const Animations = (props: Props) => {
  return (
    <div className={Styles.container}>
      <div>{"Animations (we love VTuber):"}</div>
      <div className={Styles.imageContainer}>
        <div className={Styles.video}>
          <video loop autoPlay muted>
            <source className={Styles.video} src={liora} type="video/webm" />
          </video>
        </div>
        <div className={Styles.video}>
          <video loop autoPlay muted>
            <source className={Styles.video} src={amoria} type="video/webm" />
          </video>
        </div>
        <div className={Styles.video}>
          <video loop autoPlay muted>
            <source className={Styles.video} src={serafi} type="video/webm" />
          </video>
        </div>
        <div className={Styles.video}>
          <video loop autoPlay muted>
            <source className={Styles.video} src={animation1} type="video/webm" />
          </video>
        </div>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={animation2} alt="sample" />
        </div>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={animation3} alt="sample" />
        </div>
        <div className={Styles.video}>
          <video loop autoPlay muted>
            <source className={Styles.video} src={animation4} type="video/webm" />
          </video>
        </div>
      </div>
    </div>
  )
}

export default Animations;

import React, { useState } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesDown, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faTwitter } from '@fortawesome/free-brands-svg-icons'

import HMenu from "./HMenu/HMenu";
import Projects from "./Projects/Projects";
import Artists from "./Artists/Artists";
import Team from "./Team/Team";
import Animations from "./Animations/Animations";
import Software from "./Software/Software";

import Styles from "./FrontPage.module.css";

interface Props {

}

const FrontPage = (props: Props) => {
  const [showScrollButton, setShowScrollButton] = useState<boolean>(true);
  const [english, setEnglish] = useState<boolean>(true);

  return (
    <div className={Styles.container}>
      <div className={Styles.outerBorder}>
        <div className={Styles.center}>
          <div className={Styles.firstPage}>
            <div className={Styles.flexRow}>
              <img className={Styles.image} src={"/logo.svg"} alt="TeamLogo" />
              <div className={Styles.introduction}>
                <h3>
                  Awesome Sauce?
                </h3>
                <div className={Styles.button} onClick={() => setEnglish(!english)}>English / 日本語</div>
                {english &&
                  <>
                    <p>
                      Awesome Sauce is a group of translators, designers, editors and software developers with the goal to promote Asian artists in the West.
                    </p>
                    <p>
                      We offer free or very cheap translation services as well as tips and tricks regarding western etiquette and social media to any artist.
                    </p>
                    <p>
                      We also translate manga that have no chance of ever seeing a western release. And you can commission us to translate anything.
                    </p>
                  </>
                }
                {!english &&
                  <>
                    <p>
                      Awesome Sauceは、翻訳者、デザイナー、編集者、ソフトウェア開発者が集まり、欧米でアジアのアーティストを普及させることを目的としています。
                    </p>
                    <p>
                      私たちは、あらゆるアーティストに、無料または非常に安価な翻訳サービス、西洋のエチケットやソーシャルメディアに関するヒントなどを提供しています。
                    </p>
                    <p>
                      また、欧米で発売される可能性のない漫画も翻訳しています。また、何でも翻訳を依頼することができます。
                    </p>
                  </>
                }
                <div className={Styles.socials}>
                  <a href="mailto:contact@awesomesauce.moe">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                  <a href="https://discord.com/invite/NN2G9S7">
                    <FontAwesomeIcon icon={faDiscord} />
                  </a>
                  <a href="https://twitter.com/_master117">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </div>
              </div>
            </div>
            {showScrollButton &&
              <FontAwesomeIcon className={Styles.downIcon} icon={faAnglesDown} onClick={() => {
                window.scrollBy(0, window.innerHeight);
                setShowScrollButton(false);
              }} />
            }
          </div>
          <HMenu label="Supported Artists" innerComponent={<Artists />} left open />
          <HMenu label="Translation Projects" innerComponent={<Projects />} open />
          <HMenu label="Animations" innerComponent={<Animations />} left open />
          <HMenu label="Software & Websites" innerComponent={<Software />} open />
          <HMenu label="Team" innerComponent={<Team />} left open />
          <div className={Styles.socials}>
            <a href="mailto:contact@awesomesauce.moe">
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a href="https://discord.com/invite/NN2G9S7">
              <FontAwesomeIcon icon={faDiscord} />
            </a>
            <a href="https://twitter.com/_master117">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
          <div className={Styles.imprint}>
            <a href="/imprint">Imprint & Privacy Policy</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FrontPage;

import React from 'react'

import software1 from "../../Assets/software/software1.webp";
import software2 from "../../Assets/software/software2.webp";
import software3 from "../../Assets/software/software3.webp";
import software4 from "../../Assets/software/software4.webp";
import software5 from "../../Assets/software/software5.webp";
import software6 from "../../Assets/software/software6.webp";

import Styles from "./Software.module.css";

interface Props {

}

const Software = (props: Props) => {
  return (
    <div className={Styles.container}>
      <div>{"You dream it, we build it!:"}</div>
      <div className={Styles.imageContainer}>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={software1} width="500px" alt="sample" />
          <div>Manga Libraries & Progress Tracker</div>
        </div>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={software2} width="500px" alt="sample" />
          <div>Artist Galleries</div>
        </div>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={software3} width="400px" alt="sample" />
          <div>HTML 5 & Unity Games</div>
        </div>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={software4} width="400px" alt="sample" />
          <div>Statistic Visualized</div>
        </div>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={software5} width="400px" alt="sample" />
          <div>Discord Bots</div>
        </div>
        <div className={Styles.imagebox}>
          <img className={Styles.image} src={software6} width="400px" alt="sample" />
          <div>Ai analysis tools</div>
        </div>
      </div>
    </div>
  )
}

export default Software;

import React from 'react'
import Card from "../Card/Card";

import haruhisky from "../../Assets/cards/haruhisky.webp";
import kumakuma from "../../Assets/cards/kumakuma.webp";
import kinbakuman from "../../Assets/cards/kinbakuman.webp";
import navia from "../../Assets/cards/navia.webp";
import davidwu from "../../Assets/cards/davidwu.webp";
import shiro from "../../Assets/cards/shiro.webp";
import fongban from "../../Assets/cards/fongban.webp";

import Styles from "./Artists.module.css";

interface Props {

}

const Artists = (props: Props) => {
  return (
    <div className={Styles.container}>
      <Card twitter="haruhisky1" pixivId="4956073" name="Haruhisky🔞" image={haruhisky} />
      <Card twitter="kuuma25_kuma" pixivId="43472916" name="くまくま KumaKuma" image={kumakuma} />
      <Card twitter="kinbakuman_" pixivId="10535217" name="緊縛魔🔞 Kinbakuman" image={kinbakuman} />
      <Card twitter="2015chaosgroup" pixivId="4306147" name="上村なびあ🍅" image={navia} />
      <Card twitter="SoftdevWu" name="David Wu" image={davidwu} />
      <Card twitter="shiro_asan" pixivId="378760" name="あさん🔞" image={shiro} />
      <Card twitter="Fongban_illust" pixivId="901571" name="FongBan法巴" image={fongban} />
    </div>
  )
}

export default Artists;

import React from 'react';
import mosaic from './mosaic.webp';
import artists from "./output.json";

import Styles from "./MosaicPage.module.css";

interface IMosaicPageProps {
}

const NosaicPage: React.FunctionComponent<IMosaicPageProps> = (props) => {
    const imageref = React.useRef<HTMLImageElement>(null);
    return <div>
        {artists.map((x, i) => {
            return (<a key={"Link-" + i} href={"https://twitter.com/" + x.replace("-2", "").replace("-3", "")} style={{ position: "absolute", left: Math.floor(i / 75) * 100, top: (i * 100) % 7500, width: "100px", height: "100px" }}>
                <div className={Styles.ContentDiv} >{x.replace("-2", "").replace("-3", "")}</div>
            </a>);
        })}
        <img ref={imageref} src={mosaic} alt="test" style={{ objectFit: "contain" }} />
    </div >;
};

export default NosaicPage;

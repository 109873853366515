import React from 'react'

import mangasample1 from "../../Assets/translations/mangasample1.webp";
import mangasample2 from "../../Assets/translations/mangasample2.webp";
import mangasample3 from "../../Assets/translations/mangasample3.webp";
import mangasample4 from "../../Assets/translations/mangasample4.webp";
import mangasample5 from "../../Assets/translations/mangasample5.webp";

import gamesample1 from "../../Assets/translations/gamesample1.webp";
import gamesample2 from "../../Assets/translations/gamesample2.webp";
import gamesample3 from "../../Assets/translations/gamesample3.webp";

import comicsample1 from "../../Assets/translations/comicsample1.webp";
import comicsample2 from "../../Assets/translations/comicsample2.webp";
import comicsample3 from "../../Assets/translations/comicsample3.webp";
import comicsample4 from "../../Assets/translations/comicsample4.webp";
import comicsample5 from "../../Assets/translations/comicsample5.webp";
import comicsample6 from "../../Assets/translations/comicsample6.webp";
import comicsample7 from "../../Assets/translations/comicsample7.webp";
import comicsample8 from "../../Assets/translations/comicsample8.webp";
import comicsample9 from "../../Assets/translations/comicsample9.webp";

import Styles from "./Projects.module.css";

interface Props {

}

const Projects = (props: Props) => {
  return (
    <div className={Styles.container}>
      <div>{"Manga/Doujinshi (over 117 in total!):"}</div>
      <div className={Styles.imageContainer}>
        <a href="https://www.amazon.co.jp/-/en/%E8%A5%BF%E5%87%BA-%E3%82%B1%E3%83%B3%E3%82%B4%E3%83%AD%E3%83%BC-ebook/dp/B07THTTJDG/ref=sr_1_2">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={mangasample1} width="200px" alt="sample" />
          </div>
        </a>
        <a href="https://www.amazon.co.jp/-/en/Nagaru-Tanigawa-ebook/dp/B082P8SXBN/ref=sr_1_1">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={mangasample2} width="200px" alt="sample" />
          </div>
        </a>
        <a href="https://www.amazon.co.jp/-/en/%E4%BB%B2%E8%B0%B7-%E9%B3%B0-ebook/dp/B01NAI0BJQ/ref=sr_1_1">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={mangasample3} width="200px" alt="sample" />
          </div>
        </a>
        <a href="https://www.patreon.com/haruhisky/">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={mangasample4} width="200px" alt="sample" />
          </div>
        </a>
        <a href="https://www.amazon.co.jp/-/en/Nagaru-Tanigawa-ebook/dp/B082P8SXBN/ref=sr_1_1">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={mangasample5} width="200px" alt="sample" />
          </div>
        </a>
      </div>
      <div className={Styles.divider} />
      <div>{"Video Games:"}</div>
      <div className={Styles.imageContainer}>
        <a href="https://store.steampowered.com/app/400910/RabiRibi/">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={gamesample1} width="200px" alt="sample" />
          </div>
        </a>
        <a href="https://david-wu-softdev.itch.io/mumeis-memory-juggling">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={gamesample2} width="200px" alt="sample" />
          </div>
        </a>
        <a href="https://twitter.com/holeolion/status/1538860168532078592">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={gamesample3} width="200px" alt="sample" />
          </div>
        </a>
      </div>
      <div className={Styles.divider} />
      <div>{"Web comics (over 200 in total!):"}</div>
      <div className={Styles.imageContainer}>
        <a href="https://twitter.com/kaijyu_Lv27/status/1427743034377220098">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample1} width="200px" alt="sample" />
            <div>貝獣 Lv.27</div>
          </div>
        </a>
        <a href="https://twitter.com/i/web/status/1057469579373363201">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample2} width="200px" alt="sample" />
            <div>さくたけ🐇</div>
          </div>
        </a>
        <a href="https://twitter.com/nisimetamono/status/1513108803603947523">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample3} width="200px" alt="sample" />
            <div>煮しめたもの</div>
          </div>
        </a>
        <a href="https://twitter.com/kuuma25_kuma/status/1458793746158419969">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample4} width="200px" alt="sample" />
            <div>Kuma Kuma</div>
          </div>
        </a>
        <a href="https://twitter.com/Fongban_illust/status/1538240388942602240">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample5} width="200px" alt="sample" />
            <div>FongBan法巴</div>
          </div>
        </a>
        <a href="https://twitter.com/2015chaosgroup/status/1479089309513052160">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample6} width="200px" alt="sample" />
            <div>上村なびあ🍅</div>
          </div>
        </a>
        <a href="https://twitter.com/haruhisky1/status/1392313283005190149">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample7} width="200px" alt="sample" />
            <div>Haruhisky</div>
          </div>
        </a>
        <a href="https://twitter.com/mitieru/status/1446983197145780224">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample8} width="200px" alt="sample" />
            <div>外販梅干しミッチェル</div>
          </div>
        </a>
        <a href="https://twitter.com/mitieru/status/1446983197145780224">
          <div className={Styles.imagebox}>
            <img className={Styles.image} src={comicsample9} width="200px" alt="sample" />
            <div>Kinbakuman</div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Projects;

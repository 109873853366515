import React from 'react'
import Card from "../Card/Card";

import master from "../../Assets/cards/akari.webp";
import petra from "../../Assets/cards/petra.webp";
// import rice from "../../Assets/cards/rice.webp";
// import aaron from "../../Assets/cards/aaron.webp";
import yoko from "../../Assets/cards/yoko.webp";
import hedge from "../../Assets/cards/hedge.webp";

import Styles from "./Team.module.css";

interface Props {

}

const Team = (props: Props) => {
  return (
    <div className={Styles.container}>
      <Card twitter="_master117" pixivId="4908920" name="master117" desc={["Founder, Editor, Translator, Software Engineer"]} image={master} />
      <Card twitter="MarkovichMason" name="Petra_lal" desc={["Founder, Translator"]} image={petra} />
      {/* <Card twitter="Hinamatsurice" name="Rice & Roll" desc={["Editor, Audio Engineer"]} image={rice} /> */}
      {/* <Card twitter="thiscantbeempty" name="Aaron Rodriguez" desc={["Animator, Quality Control"]} image={aaron} /> */}
      <Card name="ACB" desc={["Translator"]} image={yoko} />
      <Card twitter="HedgehogOnAnOwl" name="Hedge" desc={["Quality Control, Yuri Memes"]} image={hedge} />
    </div>
  )
}

export default Team;


import { faAngleRight, faAngleDown, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from 'react'

import Styles from "./HMenu.module.css";

interface Props {
  label: string;
  left?: boolean;
  open?: boolean
  innerComponent: JSX.Element;
}

const HMenu = (props: Props) => {
  const [showContent, setShowContent] = useState<boolean>(!!props.open);

  return (
    <div className={Styles.container} style={{
      alignSelf: props.left ? "flex-start" : "flex-end",
    }}>
      <div className={[
        Styles.menu,
        props.left ? Styles.left : Styles.right
      ].join(" ")}
        onClick={() => { if(window.innerWidth > 1070) setShowContent(!showContent) }}
      >
        <div className={Styles.text}>
          {props.label}
        </div>
        <div className={Styles.chevron}><FontAwesomeIcon icon={showContent ? faAngleDown : (props.left ? faAngleRight : faAngleLeft)} /></div>
      </div>
      {showContent &&
        <div className={Styles.content} style={{ alignSelf: props.left ? "flex-start" : "flex-end" }}>
          {props.innerComponent}
        </div>
      }
    </div>
  )
}

export default HMenu;
